.cardContainer{
   width: 100% !important;

}

/* .next-button{
   justify-content: right !important;
   width: 100px;
   float: right;
} */

.css-1k81fv-MuiStepConnector-root-eng {
   -webkit-flex: 1 1 auto !important;
   -ms-flex: 1 1 auto !important;
   flex: 1 1 auto !important;
   position: absolute !important;
   top: 12px !important;
   left: calc(-50% + 20px) !important;
   right: calc(50% + 20px) !important;
}

.css-1k81fv-MuiStepConnector-root-arabic {
   -webkit-flex: 1 1 auto !important;
   -ms-flex: 1 1 auto !important;
   flex: 1 1 auto !important;
   position: absolute !important;
   top: 12px !important;
   right: calc(-50% + 20px) !important;
   left: calc(50% + 20px) !important;
}


.step-button-container{
   display: flex;
   flex: 1;
   flex-direction: row;
   justify-content: space-between;
}