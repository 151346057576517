/* Basic Layout */
.signup {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.signup-bk-image {
  flex: 1;
  background-image: url("../images/login_back.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 10px 0 0 10px; /* Optional: Add rounded corners */

  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.signup-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 0 10px 10px 0; /* Optional: Add rounded corners */
}

.signup-image img {
  width: 55%;
  height: 55%;
}

.signup-form {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 10px 10px 0; /* Optional: Add rounded corners */
}

.signup-form-container {
  width: 100%;
  max-width: 400px; /* Limit width for the form */
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
}

.signup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.signup-header h2 {
  font-size: 1.8rem;
  color: #333;
}

.welcome-message {
  font-size: 1rem;
  color: #777;
  margin-bottom: 20px;
}

/* Form input styling */
.MuiTextField-root {
  margin-bottom: 15px;
}

/* Login button */
.signup-button {
  margin-top: 20px;
  font-weight: bold;
  background-color: #3f51b5; /* Custom primary color */
  color: #fff;
}

/* Social signup buttons */
.social-signup-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.social-signup-buttons .MuiIconButton-root {
  font-size: 30px;
}

/* Forgot Password and Register Links */
.extra-links {
  margin-top: 15px;
  text-align: center;
}

.forgot-password-link,
.register-link {
  font-size: 0.9rem;
  color: #3f51b5;
  text-transform: none;
}

.forgot-password-link:hover,
.register-link:hover {
  text-decoration: underline;
}

.input-row-container {
  display: flex;             /* Enables flexbox */
  flex-direction: row;       /* Arranges items in a row */
  justify-content: space-between; /* Ensures space is distributed between items */
  gap: 10px;                 /* Adds space between the input fields */
  padding: 10px;             /* Adds padding around the container */
}

.input-row-container > div {
  flex: 1;                   /* Ensures the inputs take up equal width */
}

/* Responsive Design */
@media (max-width: 768px) {
  .signup-container {
    flex-direction: column;
    justify-content: center; /* Center items vertically on smaller screens */
  }

  .signup-image {
    display: none;
  }

  .signup-form {
    width: 100%;
    height: auto;
  }

  .signup-form-container {
    max-width: 100%; /* Full width on smaller screens */
  }

  .signup-header h2 {
    font-size: 1.5rem; /* Adjust font size on smaller screens */
  }


}
