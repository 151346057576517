/* Basic Layout */
.login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.login-bk-image {
  flex: 1;
  background-image: url("../images/login_back.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 10px 0 0 10px; /* Optional: Add rounded corners */

  display: flex;
  justify-content: center;  /* Center horizontally */
  align-items: center;      /* Center vertically */
}

.login-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 0 10px 10px 0; /* Optional: Add rounded corners */
}

.login-image img{
  width: 55%;
  height: 55%;
}

.login-form {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 10px 10px 0; /* Optional: Add rounded corners */
}

.login-form-container {
  width: 100%;
  max-width: 400px; /* Limit width for the form */
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;

}

.login-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.login-header h2 {
  font-size: 1.8rem;
  color: #333;
}

.welcome-message {
  font-size: 1rem;
  color: #777;
  margin-bottom: 20px;
}

/* Form input styling */
.MuiTextField-root {
  margin-bottom: 15px;
}

/* Login button */
.login-button {
  margin-top: 20px;
  font-weight: bold;
  background-color: #3f51b5; /* Custom primary color */
  color: #fff;
}

/* Social login buttons */
.social-login-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.social-login-buttons .MuiIconButton-root {
  font-size: 30px;
}

/* Forgot Password and Register Links */
.extra-links {
  margin-top: 15px;
  text-align: center;
}

.forgot-password-link, .register-link {
  font-size: 0.9rem;
  color: #3f51b5;
  text-transform: none;
}

.forgot-password-link:hover, .register-link:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
    justify-content: center; /* Center items vertically on smaller screens */
  }

  .login-image {
    display: none;
  }

  .login-form {
    width: 100%;
    height: auto;
  }

  .login-form-container {
    max-width: 100%; /* Full width on smaller screens */
  }

  .login-header h2 {
    font-size: 1.5rem; /* Adjust font size on smaller screens */
  }
}
