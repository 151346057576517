.profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 60%;
    margin: 0 auto;
}

.profile-form {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.profile-pic-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.profile-avatar {
    width: 170px !important; /* Increase the size */
    height: 170px !important; /* Increase the size */
    border: 4px solid #eee;
    cursor: pointer; /* Indicate clickable avatar */
}

.input-row-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 0;
}

.update-button {
    margin-top: 20px;
    background-color: #1976d2;
    color: white;
}

.error-message {
    color: red;
    font-size: 0.875rem;
    text-align: center;
    margin-top: 5px;
}
