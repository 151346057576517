.plan-card {
  width: 250px;
  height: 400px;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.plan-card-image {
  position: relative;
  height: 150px;
  background-size: cover;
  background-position: center;
  opacity: 0.8;
}

.plan-price-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
}

.plan-price {
  color: #fff;
  font-weight: bold;
}

.plan-features {
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.feature-item {
  display: flex;
  align-items: center;
}

.feature-icon {
  margin-right: 8px;
}

.subscribe-button {
  border-radius: 0;
  height: 50px;
}
